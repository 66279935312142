<template>
	<div>
		<div v-if="dose" class="row mb-2">
			<div class="col-12 form-group">
				<label for="date">{{ $t('monte.congelation.date_collecte') }} *</label>
				<e-datepicker id="date" v-model="date" @input="init_component"></e-datepicker>
			</div>
		</div>
		<div v-if="loading" class="row">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else-if="dose" class="row">
			<div class="col-12">
				<div v-for="(stallion, index) in doses" class="row" :key="stallion.uuid">
					<div class="col-12 mb-1">
						<h3 class="d-inline">{{ stallion.horse_nom }}</h3> - <h5 class="d-inline">{{ stallion.demandeur_rs }}</h5> - {{ stallion.qte_total }} {{ $t('monte.doses') }}
					</div>
					<div v-if="stallion.alert_qte" class="col-12">
						<b-alert variant="warning" show>
							{{ $t('monte.congelation.alert_sortie_quantite') }}
						</b-alert>
					</div>
					<div class="col-12 mt-1">
						<table class="table table-hover table-sm mb-0">
							<thead>
								<tr>
									<th>{{ $t('monte.congelation.qte_sortir') }}</th>
									<th>{{ $t('monte.congelation.qte_dispo') }}</th>
									<th>{{ $t('monte.congelation.qte_initial') }}</th>
									<th>{{ $t('monte.congelation.volume') }}</th>
								</tr>
							</thead>
							<tbody v-if="stallion.doses.length > 0 && force_reload">
								<tr v-for="dose in stallion.doses" :key="dose.semencedose_id">
									<td>
										<b-input type="number" class="form-control" v-model="dose.qte_expedition" @input="checkSumDose(index)"/>
									</td>
									<td>{{ dose.solde }}</td>
									<td>{{ dose.semencedose_qte }}</td>
									<td>{{ dose.semencedose_volume }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="col-12 text-center">
				<a class="btn btn-primary mt-3" href="#" @click.prevent="checkFormDose">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
			</div>
		</div>
		<div v-else class="row">
			<div class="col-12 form-group">
				<label for="cuve_transport">{{ $t("monte.stock_semence.cuve_transport") }} *</label>
				<e-select
					id="cuve_transport"
					v-model="localisation_selected"
					track-by="semencelocalisation_id"
					label="full_name"
					:placeholder="$t('monte.stock_semence.rechercher_categorie')"
					:selectedLabel="$t('global.selected_label')"
					:options="all_localisations"
					:searchable="true"
					:show-labels="false"
					:allow-empty="true"
					:sortable="false"
					:class="{ 'is-invalid': !localisation_selected }"
				>
					<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
				</e-select>
			</div>

			<div class="col-12">
				<div v-for="(stallion, index) in emplacements" class="row" :key="stallion.uuid">
					<div class="col-12 mb-1">
						<h3 class="d-inline">{{ stallion.horse_nom }}</h3> - <h5 class="d-inline">{{ stallion.demandeur_rs }}</h5> - {{ stallion.qte_total }} {{ $t('monte.paillettes') }}<br>
						{{ stallion.norme }}
					</div>
					<div v-if="stallion.alert_qte" class="col-12">
						<b-alert variant="warning" show>
							{{ $t('monte.congelation.alert_sortie_quantite') }}
						</b-alert>
					</div>
					<div class="col-12 mt-1">
						<table class="table table-hover table-sm mb-0">
							<thead>
								<tr>
									<th>{{ $t('monte.congelation.qte_sortir') }}</th>
									<th>{{ $t('monte.congelation.qte_dispo') }}</th>
									<th>{{ $t('monte.congelation.semencelot_quantieme') }}</th>
									<th>{{ $t('monte.stock_semence.localisation') }}</th>
									<th>{{ $t('monte.congelation.norme') }}</th>
								</tr>
							</thead>
							<tbody v-if="stallion.emplacements.length > 0 && force_reload">
								<tr v-for="emplacement in stallion.emplacements.filter(emp => emp.show)" :key="emplacement.emplacementcongelation_id">
									<td>
										<b-input type="number" class="form-control" v-model="emplacement.qte_expedition" @input="checkSum(index)"/>
									</td>
									<td>{{ emplacement.emplacementcongelation_qte }}</td>
									<td>{{ emplacement.lot_quantieme }}</td>
									<td>{{ emplacement.localisation_label }}</td>
									<td>{{ emplacement.normes_label }}</td>
								</tr>
								<tr v-if="stallion.emplacements.filter(emp => !emp.show).length > 0">
									<td colspan="5" class="text-center">
										<a href="#" @click.prevent="showMore(index)"><font-awesome-icon :icon="['fal', 'angle-down']"/> {{ $t('monte.congelation.voir_plus') }} <font-awesome-icon :icon="['fal', 'angle-down']"/></a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="col-12 text-center">
				<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm(false)">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
				<a class="btn btn-secondary mt-3 ml-2" href="#" @click.prevent="openPrintModal">{{ $t('global.enregistrer_imprimer') }} <font-awesome-icon v-if="loading_courrier" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'file-alt']" /></a>
			</div>
		</div>

		<b-modal ref="modal-courrier" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.congelation.courrier_sortie_stock") }}
				</template>
			</template>

			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
						<e-select
							v-model="template"
							id="model_id"
							track-by="model_label"
							label="model_label"
							:placeholder="$t('horse.search_doc')"
							:deselectLabel="$t('global.press_enter_to_remove')"
							:selectedLabel="$t('global.selected_label')"
							:options="templates"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
						/>
					</div>
				</div>
				<div class="col-12 text-center">
					<b-button class="mt-1 rounded-pill" variant="primary" @click="confirmPrint">{{ $t("global.print") }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'file-alt']" /></b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"
	import Shutter from '@/mixins/Shutter.js'
	import Model from "@/mixins/Model.js"

	export default {
		name: 'PreparationExpedition',
		mixins: [Congelation, StockSemence, Shutter, ShutterCongelation, Model],
		props: ['expeditions'],
		data () {
			return {
				loading: false,
				all_localisations: [],
				localisation_selected: null,
				processing: false,
				emplacements: [],
				force_reload: true,
				templates: [],
				template: null,
				loading_courrier: false,
				dose: false,
				date: new Date(),
				doses: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				this.dose = this.expeditions[0].semenceexpedition_dose

				if(!this.dose) {
					const all_localisations = await this.getLocalisationSemenceFullName()
					// je récupère uniquement les cuves de transports qui ne sont pas parties et qui n'ont pas de tiers ou le même tiers que mon expédition
					this.all_localisations = all_localisations.filter(loc => loc.semencelocalisation_transport && !loc.semencelocalisation_shipped && (!loc.semencelocalisation_destinataire || loc.semencelocalisation_destinataire == this.expeditions[0].semenceexpedition_destinataire))
					this.localisation_selected = this.all_localisations.find(loc => loc.semencelocalisation_destinataire == this.expeditions[0].semenceexpedition_destinataire)

					const emplacements = await this.getLotsSuggestion(this.expeditions.map(exp => exp.semenceexpedition_id).join(','))
					this.emplacements = emplacements.map(empl => ({...empl, alert_qte: false}))
				}
				else {
					const doses = await this.getDosesAvailable(this.expeditions.map(exp => exp.semenceexpedition_id).join(','), this.date)
					this.doses = doses.map(dose => ({...dose, alert_qte: false}))
					for(let i in doses) {
						this.checkSumDose(i)
					}
				}
				this.loading = false
			},

			async checkForm(print) {
				if(!this.localisation_selected || this.processing) {
					this.failureToast('toast.all_inputs')
					return false
				}

				this.processing = true

				const expeditions = this.emplacements.map(stallion => {
					return stallion.emplacements.filter(empl => empl.qte_expedition > 0).map(empl => ({
							qte_expedition: empl.qte_expedition,
							emplacement_id: empl.emplacementcongelation_id,
					}))
				})

				const floors_ids = await this.pretExpedition(this.expeditions.map(exp => exp.semenceexpedition_id).join(','), expeditions, this.localisation_selected.semencelocalisation_id)

				if(print) {
					const emplacements_print = this.emplacements.map(stallion => {
						return {
							'emplacements': stallion.emplacements.filter(empl => empl.qte_expedition > 0).map(empl => ({
								qte_expedition: empl.qte_expedition,
								emplacement_id: empl.emplacementcongelation_id,
								emplacement_label: empl.localisation_label,
								semencelot_quantieme: empl.lot_quantieme,
								semencelot_couleur: empl.lot_couleur
							})),
							'demandeur_rs': stallion.demandeur_rs,
							'horse_id': stallion.horse_id,
							'norme': stallion.norme,
						}
					})

					await this.printSortieStocks(emplacements_print, this.expeditions[0].semenceexpedition_destinataire, this.localisation_selected.semencelocalisation_id, this.template.model_id, this.template.model_label)
				}

				this.processing = false
				this.successToast('toast.info_save_succes')
				this.ok()
				if(floors_ids.length > 0) {
					this.setUpDeleteFloors(floors_ids)
				}
				this.shutterPanel().close('expedition-preparation')
			},

			reload() {
				this.ok()
			},

			showMore(index) {
				this.emplacements[index].emplacements.forEach(empl => {
					empl.show = true
				})

				this.force_reload = false
				this.$nextTick(()=> {
					this.force_reload = true
				})
			},

			checkSum(index) {
				const sum = this.emplacements[index].emplacements.reduce((total, empl) => {
					const qte = empl.qte_expedition ? parseInt(empl.qte_expedition) : 0
					return qte + total
				}, 0)
				this.emplacements[index].alert_qte = (sum !== this.emplacements[index].qte_total)
			},

			checkSumDose(index) {
				const sum = this.doses[index].doses.reduce((total, empl) => {
					const qte = empl.qte_expedition ? parseInt(empl.qte_expedition) : 0
					return qte + total
				}, 0)
				this.doses[index].alert_qte = (sum !== this.doses[index].qte_total)
			},

			async openPrintModal() {
				this.loading_courrier = true
				this.templates = await this.loadAllModel(0, ['sortie_stock'])
				this.$refs['modal-courrier'].show()
				this.loading_courrier = false
			},

			confirmPrint() {
				if(!this.template) {
					this.failureToast('formulaire.erreur_champs_non_remplis')
					return false
				}

				this.checkForm(true)
			},

			async checkFormDose() {
				if(this.processing) {
					return false
				}

				this.processing = true

				const expeditions = this.doses.map(stallion => {
					return stallion.doses.filter(dose => dose.qte_expedition > 0).map(dose => ({
							qte_expedition: dose.qte_expedition,
							dose_id: dose.semencedose_id,
							expeditions_ids: stallion.expeditions_ids
					}))
				})

				await this.pretExpeditionDose(this.expeditions.map(exp => exp.semenceexpedition_id).join(','), expeditions, this.date)

				this.processing = false
				this.successToast('toast.info_save_succes')
				this.ok()
				this.shutterPanel().close('expedition-preparation')
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}

</script>